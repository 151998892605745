import { createTheme } from '@mui/material'

// this is how we can add custom variables to the theme with typescript, see: https://mui.com/customization/theming/#custom-variables
declare module '@mui/material/styles' {
  interface TypographyVariants {
    estimateText: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    estimateText?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    estimateText: true
  }
}

export const theme = createTheme({
  breakpoints: {
    // Try to keep it in sync with tailwind breakpoints
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  typography: {
    fontFamily: ['"Source Sans Pro"', 'Roboto', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"'].join(','),
    estimateText: {
      fontSize: '1.125rem',
      lineHeight: 1.33,
    },
  },
  palette: {
    primary: {
      main: '#3CBC45',
    },
    success: {
      main: '#3CBC45',
      // light: "#c5ebc7",
      // dark: "#27a52e",
    },
    text: {
      primary: '#000000',
      secondary: 'rgba(0, 0, 0, 0.87)',
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '1.125rem',
        },
      },
    },
  },
})
