// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://0811e17e65404b5ab74cb837c42d9e3d@o93944.ingest.sentry.io/5239651',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: process.env.SENTRY_RELEASE,
  denyUrls: ['localhost', '127.0.0.1'],
  allowUrls: [/.*nubrakes\.com.*/],
  integrations: [
    new CaptureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  attachStacktrace: true,
})
